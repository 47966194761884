/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text, ContactForm, Subtitle } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"fotky"} description={"Fotogalerie"}>
        <Column className="--menu pb--08 pt--08" menu={true} name={"xs1ebjetlc"}>
          
          <Menu >
            
            <ColumnWrapper className="menu-logo-box">
              
              <Image className="--left" alt={"Logo"} src={"https://cdn.swbpg.com/o/13892/fc4a2b670f084667abf034fdffbe8885.jpg"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":79}} srcSet={""} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"/"} target={""} content={"Hlavní stránka"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2" href={"#kontakt"} target={""} content={"Kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="--menu pb--08 pl--40 pr--40 pt--08" menu={true} name={"menu"}>
        </Column>


        <Column name={"fotogalerie"} layout={"l12"}>
          
          <ColumnWrap className="column__flex --center el--4 --full" columns={"4"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13892/6fa3dfdcc2de4616b0d4b782266427b9_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13892/6fa3dfdcc2de4616b0d4b782266427b9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13892/6fa3dfdcc2de4616b0d4b782266427b9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13892/6fa3dfdcc2de4616b0d4b782266427b9_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13892/6fa3dfdcc2de4616b0d4b782266427b9_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13892/41252f5e1ea14ae5b7dd5e1afa695df4_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13892/41252f5e1ea14ae5b7dd5e1afa695df4_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/41252f5e1ea14ae5b7dd5e1afa695df4_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/41252f5e1ea14ae5b7dd5e1afa695df4_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13892/41252f5e1ea14ae5b7dd5e1afa695df4_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13892/41252f5e1ea14ae5b7dd5e1afa695df4_s=2000x_.JPG 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13892/08a8c17fdadc4786b7abb25e088f70df_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13892/08a8c17fdadc4786b7abb25e088f70df_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/08a8c17fdadc4786b7abb25e088f70df_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/08a8c17fdadc4786b7abb25e088f70df_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13892/08a8c17fdadc4786b7abb25e088f70df_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13892/08a8c17fdadc4786b7abb25e088f70df_s=2000x_.JPG 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13892/74d3f329e7b74b98b17b3053c6ec9015_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/13892/74d3f329e7b74b98b17b3053c6ec9015_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/74d3f329e7b74b98b17b3053c6ec9015_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/74d3f329e7b74b98b17b3053c6ec9015_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13892/74d3f329e7b74b98b17b3053c6ec9015_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13892/74d3f329e7b74b98b17b3053c6ec9015_s=2000x_.JPG 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"reference"} style={{"paddingTop":null,"backgroundColor":"var(--color-variable-1--darken)"}}>
        </Column>


        <Column name={"kontakt"} style={{"backgroundColor":"var(--color-variable-1--darken)"}}>
          
          <ColumnWrap className="column__flex el--2 pb--60 pt--60" columns={"2"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--48 title-box--invert" content={"<span style=\"text-decoration-line: underline;\">Stavební a zednické práce</span>"}>
              </Title>

              <Text className="text-box fs--20 text-box--invert ls--0 lh--16" content={"Rádi Vám navrhneme řešení a připravíme nabídku ."}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--shape3 btn-box--shadow5" href={"#[[T75SecName7]]"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box fs--20 text-box--invert lh--16" content={"Pokud chcete řešit výstavbu, opravu, rekonstrukci, neváhejte se na nás obrátit."}>
              </Text>

              <Text className="text-box fs--20 w--300 text-box--invert ls--0 lh--16" content={"Provádíme stavební a zednické práce, stavbu ,obklady, příčky a podhledy, terasy, schodiště, omítky a fasády, ploty, ...&nbsp;&nbsp;"}>
              </Text>

              <Text className="text-box fs--20 text-box--invert lh--16" content={"Využijte kontaktní formulář nebo přímo uvedený kontakt&nbsp;"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--border4" name={"galerie"} style={{"paddingTop":0,"marginBottom":26,"paddingBottom":349}} border={"4"} layout={"l8"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--4 flex--stretch" columns={"4"}>
            
            <ColumnWrapper >
              
              <Image alt={"vlhké zdivo v suterénu"} src={"https://cdn.swbpg.com/t/13892/b25740b2269949f69314d06d5d3d5360_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13892/b25740b2269949f69314d06d5d3d5360_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/b25740b2269949f69314d06d5d3d5360_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/b25740b2269949f69314d06d5d3d5360_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13892/b25740b2269949f69314d06d5d3d5360_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13892/b25740b2269949f69314d06d5d3d5360_s=2000x_.JPG 2000w"} lightbox={true} description={"před"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"použití sanační omítky"} src={"https://cdn.swbpg.com/t/13892/5c729ece075d45ff95b82c30c3c7638a_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13892/5c729ece075d45ff95b82c30c3c7638a_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/5c729ece075d45ff95b82c30c3c7638a_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/5c729ece075d45ff95b82c30c3c7638a_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13892/5c729ece075d45ff95b82c30c3c7638a_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13892/5c729ece075d45ff95b82c30c3c7638a_s=2000x_.JPG 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"odkrytí stropních trámů v podkroví"} src={"https://cdn.swbpg.com/t/13892/5db154ea4fe74b309a8f1a9c92962026_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13892/5db154ea4fe74b309a8f1a9c92962026_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/5db154ea4fe74b309a8f1a9c92962026_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/5db154ea4fe74b309a8f1a9c92962026_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13892/5db154ea4fe74b309a8f1a9c92962026_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13892/5db154ea4fe74b309a8f1a9c92962026_s=2000x_.JPG 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"ošetření impregnací"} src={"https://cdn.swbpg.com/t/13892/942980a99f7e437cae0515a999de84f3_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13892/942980a99f7e437cae0515a999de84f3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13892/942980a99f7e437cae0515a999de84f3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13892/942980a99f7e437cae0515a999de84f3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13892/942980a99f7e437cae0515a999de84f3_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"provedení ztužujících táhel v úrovni podlahy"} src={"https://cdn.swbpg.com/t/13892/142e35e12fec465abba4f0725cd67da2_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13892/142e35e12fec465abba4f0725cd67da2_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/142e35e12fec465abba4f0725cd67da2_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/142e35e12fec465abba4f0725cd67da2_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13892/142e35e12fec465abba4f0725cd67da2_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13892/142e35e12fec465abba4f0725cd67da2_s=2000x_.JPG 2000w"}>
              </Image>

              <Image alt={"provedení ztužujících táhel v úrovni podlahy"} src={"https://cdn.swbpg.com/t/13892/d118a8bde8164af48b197815db0de183_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13892/d118a8bde8164af48b197815db0de183_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/d118a8bde8164af48b197815db0de183_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/d118a8bde8164af48b197815db0de183_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13892/d118a8bde8164af48b197815db0de183_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13892/d118a8bde8164af48b197815db0de183_s=2000x_.JPG 2000w"}>
              </Image>

              <Image alt={"provedení ztužujících táhel v úrovni podlahy"} src={"https://cdn.swbpg.com/t/13892/f51a1cbf5fc64f0bb0b952d16069df89_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13892/f51a1cbf5fc64f0bb0b952d16069df89_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/f51a1cbf5fc64f0bb0b952d16069df89_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/f51a1cbf5fc64f0bb0b952d16069df89_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13892/f51a1cbf5fc64f0bb0b952d16069df89_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13892/f51a1cbf5fc64f0bb0b952d16069df89_s=2000x_.JPG 2000w"}>
              </Image>

              <Image alt={"úprava trámů krovu pro pohledovou část vinteriéru"} src={"https://cdn.swbpg.com/t/13892/f12df729a5b54a52aef406a23b2cd1a5_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13892/f12df729a5b54a52aef406a23b2cd1a5_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13892/f12df729a5b54a52aef406a23b2cd1a5_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13892/f12df729a5b54a52aef406a23b2cd1a5_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13892/f12df729a5b54a52aef406a23b2cd1a5_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13892/f12df729a5b54a52aef406a23b2cd1a5_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"úprava trámů krovu pro pohledovou část vinteriéru"} src={"https://cdn.swbpg.com/t/13892/b961385e421a47c8bcc66da5f1c976da_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13892/b961385e421a47c8bcc66da5f1c976da_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/b961385e421a47c8bcc66da5f1c976da_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/b961385e421a47c8bcc66da5f1c976da_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13892/b961385e421a47c8bcc66da5f1c976da_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13892/b961385e421a47c8bcc66da5f1c976da_s=2000x_.JPG 2000w"}>
              </Image>

              <Image alt={"úprava trámů krovu pro pohledovou část vinteriéru"} src={"https://cdn.swbpg.com/t/13892/b1e5ba66949b426485f207f90c13d3df_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13892/b1e5ba66949b426485f207f90c13d3df_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/b1e5ba66949b426485f207f90c13d3df_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/b1e5ba66949b426485f207f90c13d3df_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13892/b1e5ba66949b426485f207f90c13d3df_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13892/b1e5ba66949b426485f207f90c13d3df_s=2000x_.JPG 2000w"}>
              </Image>

              <Image alt={"úprava trámů krovu pro pohledovou část vinteriéru"} src={"https://cdn.swbpg.com/t/13892/5be6cd79188942f69697d01d87e79d10_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13892/5be6cd79188942f69697d01d87e79d10_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/5be6cd79188942f69697d01d87e79d10_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/5be6cd79188942f69697d01d87e79d10_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13892/5be6cd79188942f69697d01d87e79d10_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13892/5be6cd79188942f69697d01d87e79d10_s=2000x_.JPG 2000w"}>
              </Image>

              <Image alt={"úprava trámů krovu pro pohledovou část vinteriéru"} src={"https://cdn.swbpg.com/t/13892/49f153bcb31e4d3382b2c84af0f7eee3_s=660x_.JPG"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13892/49f153bcb31e4d3382b2c84af0f7eee3_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/49f153bcb31e4d3382b2c84af0f7eee3_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/49f153bcb31e4d3382b2c84af0f7eee3_s=860x_.JPG 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":312}}>
              
              <Image alt={"z průběhu prací"} src={"https://cdn.swbpg.com/t/13892/34067a87e8424cfe8efd187f51a88c8c_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13892/34067a87e8424cfe8efd187f51a88c8c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13892/34067a87e8424cfe8efd187f51a88c8c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13892/34067a87e8424cfe8efd187f51a88c8c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13892/34067a87e8424cfe8efd187f51a88c8c_s=1400x_.jpg 1400w"}>
              </Image>

              <Image alt={"z průběhu prací"} src={"https://cdn.swbpg.com/t/13892/bd9eaff3e15f4286b9d49f0c633238e6_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13892/bd9eaff3e15f4286b9d49f0c633238e6_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/bd9eaff3e15f4286b9d49f0c633238e6_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/bd9eaff3e15f4286b9d49f0c633238e6_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13892/bd9eaff3e15f4286b9d49f0c633238e6_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13892/bd9eaff3e15f4286b9d49f0c633238e6_s=2000x_.JPG 2000w"}>
              </Image>

              <Image alt={"z průběhu prací"} src={"https://cdn.swbpg.com/t/13892/1214430252d849deb4e7e02a8a9ce928_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13892/1214430252d849deb4e7e02a8a9ce928_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/1214430252d849deb4e7e02a8a9ce928_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/1214430252d849deb4e7e02a8a9ce928_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13892/1214430252d849deb4e7e02a8a9ce928_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13892/1214430252d849deb4e7e02a8a9ce928_s=2000x_.JPG 2000w"}>
              </Image>

              <Image alt={"z průběhu prací"} src={"https://cdn.swbpg.com/t/13892/7d767cce2a8843da920f6ea026b895c9_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13892/7d767cce2a8843da920f6ea026b895c9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13892/7d767cce2a8843da920f6ea026b895c9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13892/7d767cce2a8843da920f6ea026b895c9_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13892/7d767cce2a8843da920f6ea026b895c9_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13892/7d767cce2a8843da920f6ea026b895c9_s=2000x_.jpg 2000w"}>
              </Image>

              <Image alt={""} src={"https://cdn.swbpg.com/t/13892/bcf31bf7be854d64b5365627a86cdb86_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/13892/bcf31bf7be854d64b5365627a86cdb86_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13892/bcf31bf7be854d64b5365627a86cdb86_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13892/bcf31bf7be854d64b5365627a86cdb86_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13892/bcf31bf7be854d64b5365627a86cdb86_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13892/bcf31bf7be854d64b5365627a86cdb86_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"broušení nahrubo"} src={"https://cdn.swbpg.com/t/13892/05268f2e3c5f4c7795d252881481507c_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13892/05268f2e3c5f4c7795d252881481507c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13892/05268f2e3c5f4c7795d252881481507c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13892/05268f2e3c5f4c7795d252881481507c_s=860x_.jpg 860w"}>
              </Image>

              <Image alt={"broušení nahrubo"} src={"https://cdn.swbpg.com/t/13892/67d93061c9204315b26698bce9ccbcdf_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13892/67d93061c9204315b26698bce9ccbcdf_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/67d93061c9204315b26698bce9ccbcdf_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/67d93061c9204315b26698bce9ccbcdf_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13892/67d93061c9204315b26698bce9ccbcdf_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13892/67d93061c9204315b26698bce9ccbcdf_s=2000x_.JPG 2000w"}>
              </Image>

              <Image alt={"broušení nahrubo"} src={"https://cdn.swbpg.com/t/13892/56af6b7fa8534116b4ca492a18a73d23_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13892/56af6b7fa8534116b4ca492a18a73d23_s=350x_.JPG 350w, https://cdn.swbpg.com/t/13892/56af6b7fa8534116b4ca492a18a73d23_s=660x_.JPG 660w, https://cdn.swbpg.com/t/13892/56af6b7fa8534116b4ca492a18a73d23_s=860x_.JPG 860w, https://cdn.swbpg.com/t/13892/56af6b7fa8534116b4ca492a18a73d23_s=1400x_.JPG 1400w, https://cdn.swbpg.com/t/13892/56af6b7fa8534116b4ca492a18a73d23_s=2000x_.JPG 2000w"}>
              </Image>

              <Image alt={"broušení nahrubo"} src={"https://cdn.swbpg.com/t/13892/98a063d7874545d993d139e61ca15362_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13892/98a063d7874545d993d139e61ca15362_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13892/98a063d7874545d993d139e61ca15362_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13892/98a063d7874545d993d139e61ca15362_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13892/98a063d7874545d993d139e61ca15362_s=1400x_.jpg 1400w"}>
              </Image>

              <Image alt={"broušení nahrubo"} src={"https://cdn.swbpg.com/t/13892/fe59b3f86ede46508511a21f522121f6_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/13892/fe59b3f86ede46508511a21f522121f6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13892/fe59b3f86ede46508511a21f522121f6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13892/fe59b3f86ede46508511a21f522121f6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13892/fe59b3f86ede46508511a21f522121f6_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13892/fe59b3f86ede46508511a21f522121f6_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-cg66vr bg--bottom pb--80 pt--80" name={"kontakt-2"} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/83/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/83/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/83/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/83/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/83/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/83/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/83/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/i/template/83/fullscreen_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--25 pt--25" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--48 title-box--invert" content={"Ozveme se Vám"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --shape2 --style3 fs--18" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Kontaktní e-mail","type":"email","placeholder":"Zadejte Váš e-mail"},{"name":"Kontaktovat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"paticka"}>
          
          <ColumnWrap className="column__flex el--3 mb--60 mt--60" columns={"3"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Kontakty"} maxWidth={"240"}>
              </Title>

              <Subtitle className="subtitle-box fs--18" content={"<span style=\"color: rgb(119, 149, 162);\"><br>ozvi.se@hezke-stavby.cz</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--18" content={"<span style=\"color: rgb(119, 149, 162);\">739 605 898</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Info"} maxWidth={"180"}>
              </Title>

              <Text className="text-box fs--16 lh--16" style={{"maxWidth":1000}} content={"Hezké stavby s.r.o.<br>Nezvěstice 38,&nbsp;NEZVĚSTICE 332 04<br>IČO: 17055563<br>DIČ: CZ17055563<br>"} maxWidth={"240"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"Navigace"} maxWidth={"240"}>
              </Title>

              <Text className="text-box fs--16 lh--16" content={"<a href=\"/fotky#menu\">Zpět nahoru</a>"} maxWidth={"240"}>
              </Text>

              <Text className="text-box fs--16 lh--16 mt--0" content={"<a href=\"/#sluzby\">Služby</a>"} maxWidth={"240"}>
              </Text>

              <Text className="text-box fs--16 lh--16 mt--0" content={"<a href=\"/#reference\">Reference</a>"} maxWidth={"240"}>
              </Text>

              <Text className="text-box fs--16 lh--16 mt--0" content={"<a href=\"/#paticka\">Informace</a>"} maxWidth={"240"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}